<template>
    <div>
        <h2 class="mb-1">Clinician Report</h2>

        <div style="display: flex; flex-wrap: wrap; align-items: center;">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>
            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>
            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <b-form-group
                label="Clinician Name"
                v-if="!isClinician"
            >
                <v-select
                    v-model="clinicianName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Clinician Name"
                    label="full"
                    :dense="true"
                    :options="optionsClinicianName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Client Name"
            >
                <v-select
                    v-model="clientName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Client Name"
                    label="value"
                    :dense="true"
                    :options="optionsClientName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Region"
            >
                <v-select
                    v-model="region"
                    placeholder="Region"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsRegion"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Borough"
                v-if="region === 'NYC'"
            >
                <v-select
                    v-model="clinicianBorough"
                    placeholder="Borough"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsBorough"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Claim Date Month"
                class="ml-2"
            >
                <v-select
                    v-model="claimDateMonth"
                    placeholder="Claim Date Month"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsClaimDateMonth"
                    style="background: #fff; min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Claim Date Year"
            >
                <v-select
                    v-model="claimDateYear"
                    placeholder="Claim Date Year"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsClaimDateYear"
                    style="background: #fff; min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>

            <div>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="mb-1 mt-2 p-75"
                    @click="activeFilterFunction"
                >
                    <feather-icon
                        icon="Xicon"
                        class="mr-50"
                    />
                    <span class="align-middle">Reset filters</span>
                </b-button>
            </div>
        </div>

        <b-row class="mt-1 mb-2" v-if="isClinician">
            <b-table
                :items="arrayAllClaims"
                :fields="fieldsTable"
                responsive="sm"
                :busy="isBusy"
                show-empty
                class="clinicianReportTable"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">Loading...</strong>
                    </div>
                </template>

                <template #empty="">
                    No results
                </template>

                <template #cell(clientID)="data">
                    #{{data.item.clientID}}
                </template>
            </b-table>
        </b-row>
        <b-row class="mt-1 mb-2" v-else-if="activeFilters">
            <b-table
                :items="arrayAllClaims"
                :fields="fieldsTable"
                responsive="sm"
                :busy="isBusy || !firstLoadDOM"
                show-empty
                class="clinicianReportTable"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">Loading...</strong>
                    </div>
                </template>

                <template #empty="">
                    No results
                </template>

                <template #cell(clientID)="data">
                    #{{data.item.clientID}}
                </template>
            </b-table>
            <div class="text-center text-primary my-2 w-100" v-if="loadingInfiniteScroll && !isBusy">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-1">Loading...</strong>
            </div>
            <div class="text-center text-primary my-2 w-100" v-if="arrayAllClaims.length < arrayFiltradoReportsClinician.length && !isBusy && firstLoadDOM">
                <b-button
                    variant="outline-primary"
                    class="mt-1"
                    :disabled="loadingInfiniteScroll && !isBusy ? true : false"
                    @click="loadMoreClaims"
                >
                Load more
                </b-button>
            </div>
        </b-row>
        <b-row class="mt-1 mb-2" v-else>
            <b-table
                :items="arrayAllClaims"
                :fields="fieldsTable"
                responsive="sm"
                :busy="isBusy"
                show-empty
                class="clinicianReportTable"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">Loading...</strong>
                    </div>
                </template>

                <template #empty="">
                    No results
                </template>

                <template #cell(clientID)="data">
                    #{{data.item.clientID}}
                </template>
            </b-table>
            <div class="text-center text-primary my-2 w-100" v-if="loadingInfiniteScroll && !isBusy">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-1">Loading...</strong>
            </div>
            <div class="text-center text-primary my-2 w-100" v-if="arrayAllClaims.length < arrayFiltradoReportsClinician.length || arrayFiltradoReportsClinician.length === 0">
                <b-button
                    variant="outline-primary"
                    class="mt-1"
                    :disabled="loadingInfiniteScroll && !isBusy ? true : false"
                    @click="loadMoreClaims"
                >
                Load more
                </b-button>
            </div>
        </b-row>
        
        <div
            class="mb-1 btn btn-default"
            style="background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1;"
            @click="checkReadyToExport"
            v-if="isDataSpecialist && json_data.length === 0 && arrayFiltradoReportsClinician.length === 0"
        >
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </div>
        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-clinician.csv"
            :style="'background: #94221e; color: #fff; cursor: pointer; opacity: 1;'"
            delimiter=","
            v-if="isDataSpecialist && json_data.length >= 1 && arrayFiltradoReportsClinician.length"
        >
            CSV Export
        </download-csv>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio, BContainer,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            isDataSpecialist: false,
            expandFilter: false,
            readyToExport: true,
            isClinician: '',
            fieldsTable: [
                { key: 'claimDateFormat', label: 'Claim Date', sortable: false },
                { key: 'submittedBy', label: 'Submitted By', sortable: false },
                { key: 'clientFormat', label: 'Client IDs/client name', sortable: false },
                { key: 'amountBilledToCFE', label: 'Claim Totals', sortable: false },
                { key: 'totalamtpaid', label: 'Totals amt paid', sortable: false },
                { key: 'noIndividualSessions', label: '# of Individual In-Person Sessions', sortable: false },
                { key: 'noGroupSessions', label: '# of Group Sessions', sortable: false },
                { key: 'noRemoteSessions', label: '# of Remote Sessions', sortable: false },
                { key: 'noAssessmentSessions', label: '# of Assessment Sessions', sortable: false },
                { key: 'noCoupleSessions', label: '# of Couple Sessions', sortable: false },
                { key: 'noFamilySessions', label: '# of Family Sessions', sortable: false },
            ],
            arrayAllClaims: [],
            latestDoc: null,
            loadingInfiniteScroll: false,

            // filters
            startDate: null,
            endDate: null,
            clinicianName: '',
            clientName: '',
            noIndividualSessions: null,
            noGroupSessions: null,
            noRemoteSessions: null,
            noAssessmentSessions: null,
            noCoupleSessions: null,
            noFamilySessions: null,
            isInsuranceUtilizedForClaim: '',
            clientProgressThisMonth: '',
            clientReportsSatisfactionWithTreatmentSessions: '',
            didClientProvideCopay: '',
            didClientUtilizeSlidingScale: '',
            region: '',
            insuranceRates: '',
            didClientProvideCopay: '',
            didclientUtilizeSlidingScale: '',
            claimDateMonth: '',
            claimDateYear: '',

            // old filters
            language: '',
            progress: '',
            insurance: '',

            // data reports
            totalClaims: 0,
            countClientSatisfaction: 0,
            countNA: 0,
            countNoChange: 0,
            countImproved: 0,
            countDeclined: 0,
            countTotalSlidingScale: 0,
            countAverageCopayPerSession: 0,
            arrayClientsIndividualClientsSeen: [],
            arrayFiltradoReportsGeneralIndividualClientsSeen: [],
            counterInsuranceUtilizedForSession: 0,
            counterInsuranceReimbursementAmount: 0,
            counterclientsThatProvidedCopay: 0,
            counterAverageofCopay: 0,
            counterTotalSlidingScale: 0,
            counterAmountofSlidingScale: 0,
            counterClientDoesntHaveInsurance: 0,
            counterDeductibleNotMet: 0,
            counterDontAcceptClientsInsurance: 0,
            counterInsuranceDoesNotCoverTelehealthServices: 0,
            counterOther: 0,

            // vars atomic
            countTotalClientsInsured: 0,
            countTotalClientsInsuredRate: 0,
            countAverageCopayPerSessionAtomic: 0,
            totalInsuranceReimbursementRateForIndividualSession: 0,

            // json export csv
            json_data: [],

            // options
            optionsClinicianName: [],
            optionsClientName: [],
            optionsIsInsuranceUtilizedForClaim: ['Yes', 'No'],
            optionsClientProgressThisMonth: ['N/A','Declined','Improved','No Change'],
            optionsClientReportsSatisfactionWithTreatmentSessions: ['Yes', 'No'],
            optionsDidClientProvideCopay: ['Yes', 'No'],
            optionsDidClientUtilizeSlidingScale: ['Yes', 'No'],
            optionsRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                'Any Borough',
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island',
            ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            clinicianProgram: null,
            optionsProgram: [],
            optionsConnected: ['Yes', 'No'],
            // survey
            isClientInsured: '',
            isClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
            isTheClinicianBillingTheClientsInsurance: '',
            whyInsuranceWasNotBilled: '',
            reasonWhyClientWasReferredToClinician: '',
            optionsYesNoSurvey: ['Yes', 'No'],
            optionsWhyInsuranceWasNotBilled: [
                'Cost of deductible',
                'Doesn’t want employer to find out',
                'Doesn’t want family member to find out',
                'Other'
            ],
            optionsReasonWhyClientWasReferredToClinician: [
                'Language',
                'Distance',
                'Religion',
                'Culture',
                'Gender',
                'Remote care services',
                'Fear/Stigma',
                'Availability',
                'Client requested specific clinician',
                'Other'
            ],
            optionsClaimDateMonth: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            optionsClaimDateYear: [],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalAssessmentSessions: 0,
            totalFamilySessions: 0,
            totalCoupleSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalNonEnglishClientsStatic: 0,
            totalCopayWithoutFilters: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: false,
            isBusyClientsSeen: true,
            loadTotalClients: true,
            activeFilters: false,
            firstLoadDOM: false,
            firstLoadData: true,
            seenClients: []
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        BContainer,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsClinician'])
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        startDate(val) {
            // console.log(val)
            this.isBusy = true
            this.checkActiveFilters()
            this.searchReportsClinicianStartDate(val)
            this.functionDateRange(this.selectedRangeDate)
            if(!val) {
                this.filterRestard()
            }
            this.rangeDinamic ? this.rangeDinamic = false : ''
        },
        endDate(val) {
            // console.log(val)
            this.isBusy = true
            this.checkActiveFilters()
            this.searchReportsClinicianEndDate(val)
            if(!val) {
                this.filterRestard()
            }
        },
        clinicianName(val) {
            // console.log(val)
            if(val) {
                if(val.value === 'All Clinicians') {
                    this.isBusy = true
                    this.clinicianName = null
                    this.checkActiveFilters()
                } else {
                    // console.log(val.uid)
                    this.isBusy = true
                    this.checkActiveFilters()
                    if(this.isClinician) {
                        // .where('status', '==', 'active')
                        db.collection('clientsClaims').where('clinician', '==', val.uid).get()
                        .then(engaged => {
                            // this.optionsClient = []
                            var dataClaim = []
                            engaged.forEach(clients => {
                                var datePaidDB = clients.data().datePaid ? clients.data().datePaid : ''
                                var datePaidToFormatMonth = ''
                                var datePaidToFormatYear = ''
                                if(datePaidDB) {
                                    var datePaidToFormatMonth = moment(datePaidDB).format('MMMM')
                                    var datePaidToFormatYear = moment(datePaidDB).format('YYYY')
                                }

                                dataClaim.push({
                                    id: clients.id,
                                    datePaid: null,
                                    clinician: clients.data().clinician ? clients.data().clinician : '',
                                    client: clients.data().client ? clients.data().client : '',
                                    claimDateMonth: datePaidToFormatMonth ? datePaidToFormatMonth : '',
                                    claimDateYear: datePaidToFormatYear ? datePaidToFormatYear : '',
                                    clinicianProgram: clients.data().clinicianProgram ? clients.data().clinicianProgram : '',
                                    clinicianID: clients.data().clinicianID ? clients.data().clinicianID : '',
                                    clinicianName: clients.data().clinicianName ? clients.data().clinicianName : '',
                                    regionClinician: clients.data().regionClinician ? clients.data().regionClinician : clients.data().region ? clients.data().region : '',
                                    clientID: clients.data().clientID ? clients.data().clientID : '',
                                    clientName: clients.data().clientName ? clients.data().clientName : '',
                                    clientFormat: (clients.data().clientName ? clients.data().clientName : '') + '(' + (clients.data().clientID ? clients.data().clientID : '') + ')',
                                    claimDate: clients.data().claimDateMonth ? '01/' + moment().month(clients.data().claimDateMonth).format('MM') + '/' + clients.data().claimDateYear : '',
                                    claimDateFormat: clients.data().claimDateMonth ? moment().month(clients.data().claimDateMonth).format('MM') + '/' + clients.data().claimDateYear : '',
                                    submittedBy: clients.data().createdByName ? clients.data().createdByName : clients.data().email ? clients.data().email : '',
                                    noIndividualSessions: clients.data().noIndividualSessions ? clients.data().noIndividualSessions : '0',
                                    noGroupSessions: clients.data().noGroupSessions ? clients.data().noGroupSessions : '0',
                                    noRemoteSessions: clients.data().noRemoteSessions ? clients.data().noRemoteSessions : '0',
                                    noPhoneSessions: clients.data().noPhoneSessions ? clients.data().noPhoneSessions : '0',
                                    noVideoSessions: clients.data().noVideoSessions ? clients.data().noVideoSessions : '0',
                                    noAssessmentSessions: clients.data().noAssessmentSessions ? clients.data().noAssessmentSessions : '0',
                                    noCoupleSessions: clients.data().noCoupleSessions ? clients.data().noCoupleSessions : '0',
                                    noFamilySessions: clients.data().noFamilySessions ? clients.data().noFamilySessions : '0',
                                    amountBilledToCFE: clients.data().amountBilledToCFE ? (clients.data().amountBilledToCFE.includes('$') ? clients.data().amountBilledToCFE : '$'+clients.data().amountBilledToCFE) : '$0',
                                    totalamtpaid: '$0'
                                })
                            })
                            // console.log(dataClaim.length)
                            this.arrayAllClaims = dataClaim
                            this.setSnapshotClientActivityReportsClinician(dataClaim)
                        })
                        this.searchReportsClinicianClinicianName(val.uid)
                    } else {
                        this.searchReportsClinicianClinicianName(val.uid)
                    }
                }
            } else {
                this.isBusy = true
                this.checkActiveFilters()
                this.filterRestard()
                this.searchReportsClinicianClinicianName('')
            }
        },
        clientName(val) {
            // console.log(val)
            if(val) {
                if(val.value === 'All Clients') {
                    this.isBusy = true
                    this.clientName = null
                    this.checkActiveFilters()
                } else {
                    this.isBusy = true
                    this.checkActiveFilters()
                    this.searchReportsClinicianClientName(val.uid)
                }
            } else {
                this.isBusy = true
                this.checkActiveFilters()
                this.filterRestard()
                this.searchReportsClinicianClientName('')
            }
        },
        region(val) {
            // console.log(val)
            this.isBusy = true
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsClinicianRegion(val)
            this.checkActiveFilters()
        },
        clinicianBorough(val) {
            // console.log(val)
            this.isBusy = true
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsClinicianRegion(val === 'Any Borough' ? null : val)
            this.checkActiveFilters()
        },
        clinicianProgram(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsClinicianProgram(val)
            this.checkActiveFilters()
        },
        claimDateMonth(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsClinicianClaimDateMonth(val)
            this.checkActiveFilters()
        },
        claimDateYear(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsClinicianClaimDateYear(val)
            this.checkActiveFilters()
        },
        arrayFiltradoReportsClinician(val) {
            // this.counterInsuranceUtilizedForSession = 0
            // this.counterInsuranceReimbursementAmount = 0
            // this.counterclientsThatProvidedCopay = 0
            // this.counterAverageofCopay = 0
            // this.counterTotalSlidingScale = 0
            // this.counterAmountofSlidingScale = 0
            // this.counterClientDoesntHaveInsurance = 0
            // this.counterDeductibleNotMet = 0
            // this.counterDontAcceptClientsInsurance = 0
            // this.counterInsuranceDoesNotCoverTelehealthServices = 0
            // this.counterOther = 0
            var arrayExport = []
            // var averageCopay = 0
            this.readyToExport = false
            this.isBusy = true
            this.totalRows = 0
            this.arrayAllClaims = []
            // console.log('getAllData')
            !this.isClinician ? this.getClaimsServer() : null
            val.forEach(dataClaim => {
                arrayExport.push({
                    // client: clients.data().client ? clients.data().client : '',
                    // claimDateMonth: clients.data().claimDateMonth ? clients.data().claimDateMonth : '',
                    // claimDateYear: clients.data().claimDateYear ? clients.data().claimDateYear : '',
                    startDate: this.startDate ? moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                    endDate: this.startDate ? moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                    clientID: dataClaim.clientID,
                    clientName: dataClaim.clientName,
                    clinicianID: dataClaim.clinicianID,
                    clinicianName: dataClaim.clinicianName,
                    clinicianProgram: dataClaim.clinicianProgram,
                    claimDate: dataClaim.claimDate ? moment(dataClaim.claimDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                    submittedBy: dataClaim.submittedBy ? dataClaim.submittedBy : '',
                    noIndividualSessions: dataClaim.noIndividualSessions,
                    noGroupSessions: dataClaim.noGroupSessions,
                    noRemoteSessions: dataClaim.noRemoteSessions,
                    noPhoneSessions: dataClaim.noPhoneSessions,
                    noVideoSessions: dataClaim.noVideoSessions,
                    noAssessmentSessions: dataClaim.noAssessmentSessions,
                    noCoupleSessions: dataClaim.noCoupleSessions,
                    noFamilySessions: dataClaim.noFamilySessions,
                    totalAmountPaid: dataClaim.amountBilledToCFE
                })

                this.isClinician ? this.arrayAllClaims.push(dataClaim) : null
            })
            this.json_data = arrayExport
            this.readyToExport = true
            this.isBusy = false
        }
    },
    methods: {
        ...mapActions(['setSnapshotClientActivityReportsClinician','searchReportsClinicianStartDate','searchReportsClinicianEndDate','searchReportsClinicianClinicianName','searchReportsClinicianClientName','searchReportsClinicianRegion','searchReportsClinicianProgram','searchReportsClinicianClaimDateMonth','searchReportsClinicianClaimDateYear']),
        activeFilterFunction() {
            this.startDate = null
            this.endDate = null
            this.isClinician ? null : this.clinicianName = ''
            this.clientName = ''
            this.noIndividualSessions = null
            this.noGroupSessions = null
            this.noRemoteSessions = null
            this.noAssessmentSessions = null
            this.noCoupleSessions = null
            this.noFamilySessions = null
            this.isInsuranceUtilizedForClaim = ''
            this.clientProgressThisMonth = ''
            this.clientReportsSatisfactionWithTreatmentSessions = ''
            this.didClientProvideCopay = ''
            this.didClientUtilizeSlidingScale = ''
            this.region = ''
            this.insuranceRates = ''
            this.didClientProvideCopay = ''
            this.didclientUtilizeSlidingScale = ''
            this.claimDateMonth = ''
            this.claimDateYear = ''
            this.clinicianProgram = null
            this.clinicianBorough = null
            this.activeFilters = false
        },
        checkActiveFilters() {
            if(this.startDate || this.endDate || this.clinicianName || this.clientName || this.region || this.clinicianBorough || this.clinicianProgram || this.claimDateMonth || this.claimDateYear) {
                // console.log('active filters')
                this.isBusy = true
                this.activeFilters = true
            } else {
                // console.log('no filters')
                this.isBusy = true
                this.activeFilters = false
                !this.isClinician ? this.getClaimsLocal() : null
            }
        },
        checkReadyToExport() {
            if(this.json_data.length === 0) {
                this.readyToExport = false
            } else {
                this.readyToExport = true
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                    // console.log(formatDate1,expiredDate)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
            
        },
        filterRestard() {
            // !this.firstLoadData ? this.isBusyClientsSeen = false : ''
            // this.loadTotalClients = true
            // db.collection('clients').where('primaryLanguage', 'not-in', ['English', 'english']).get()
            // .then(docsClients => {
            //     // console.log(docsClients.size)
            //     this.totalNonEnglishClients = 0
            //     this.clientSatisfaction = 0
            //     docsClients.forEach(itemClient => {
            //         if(itemClient.data().primaryLanguage) {
            //             var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
            //             // Total Non-English Clients
            //             if(!languageLowerCase.includes("english")) {
            //                 this.totalNonEnglishClients += 1
            //             }
            //         } else {
            //             this.totalNonEnglishClients += 1
            //         }
            //     })
            //     this.totalNonEnglishClientsStatic = this.totalNonEnglishClients
            //     this.loadTotalClients = false
            // })
        },
        getClaimsServer() {
            // console.log('first')
            var counterClaimsEach = 0
            this.arrayFiltradoReportsClinician.forEach(dataClientClaim => {
                var alreadyInArrayfilter = this.arrayAllClaims.filter(ele => ele.id === dataClientClaim.id)
                if(counterClaimsEach <= 50 && alreadyInArrayfilter.length === 0) {
                    counterClaimsEach += 1
                    this.arrayAllClaims.push(dataClientClaim)
                    this.totalRows += 1
                }
            })
            // console.log(this.arrayAllClaims.length,counterClaimsEach)
            // this.latestDoc = data.docs[data.docs.length - 1]
            setTimeout(() => {
                this.loadingInfiniteScroll = false
            }, 750)
            this.arrayFiltradoReportsClinician.length >= 1 ? this.isBusy = false : null
        },
        async getClaimsLocal() {
            this.firstLoadData = true
            const ref = db.collection("clientsClaims").where('approvalStatus', '==', 'paid').where('status', '>=', 'active').orderBy('status').orderBy("claimDate", "desc").startAfter(this.latestDoc || 0).limit(50)
            const data = await ref.get()

            // ouputs docs
            // .then(docsClients => {
            data.docs.forEach(dataClientClaim => {
                // console.log(dataClientClaim.data().status)
                var datePaidDB = dataClientClaim.data().datePaid ? dataClientClaim.data().datePaid : ''
                var datePaidToFormatMonth = ''
                var datePaidToFormatYear = ''
                if(datePaidDB) {
                    var datePaidToFormatMonth = moment(datePaidDB).format('MMMM')
                    var datePaidToFormatYear = moment(datePaidDB).format('YYYY')
                }

                this.arrayAllClaims.push({
                    id: dataClientClaim.id,
                    datePaid: null,
                    clinician: dataClientClaim.data().clinician ? dataClientClaim.data().clinician : '',
                    client: dataClientClaim.data().client ? dataClientClaim.data().client : '',
                    claimDateMonth: datePaidToFormatMonth ? datePaidToFormatMonth : '',
                    claimDateYear: datePaidToFormatYear ? datePaidToFormatYear : '',
                    clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : '',
                    clinicianID: dataClientClaim.data().clinicianID ? dataClientClaim.data().clinicianID : '',
                    clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                    regionClinician: dataClientClaim.data().regionClinician ? dataClientClaim.data().regionClinician : dataClientClaim.data().region ? dataClientClaim.data().region : '',
                    clientID: dataClientClaim.data().clientID ? dataClientClaim.data().clientID : '',
                    clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                    clientFormat: (dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '') + '(' + (dataClientClaim.data().clientID ? dataClientClaim.data().clientID : '') + ')',
                    claimDate: dataClientClaim.data().claimDateMonth ? '01/' + moment().month(dataClientClaim.data().claimDateMonth).format('MM') + '/' + dataClientClaim.data().claimDateYear : '',
                    claimDateFormat: dataClientClaim.data().claimDateMonth ? moment().month(dataClientClaim.data().claimDateMonth).format('MM') + '/' + dataClientClaim.data().claimDateYear : '',
                    submittedBy: dataClientClaim.data().createdByName ? dataClientClaim.data().createdByName : dataClientClaim.data().email ? dataClientClaim.data().email : '',
                    noIndividualSessions: dataClientClaim.data().noIndividualSessions ? dataClientClaim.data().noIndividualSessions : '0',
                    noGroupSessions: dataClientClaim.data().noGroupSessions ? dataClientClaim.data().noGroupSessions : '0',
                    noRemoteSessions: dataClientClaim.data().noRemoteSessions ? dataClientClaim.data().noRemoteSessions : '0',
                    noPhoneSessions: dataClientClaim.data().noPhoneSessions ? dataClientClaim.data().noPhoneSessions : '0',
                    noVideoSessions: dataClientClaim.data().noVideoSessions ? dataClientClaim.data().noVideoSessions : '0',
                    noAssessmentSessions: dataClientClaim.data().noAssessmentSessions ? dataClientClaim.data().noAssessmentSessions : '0',
                    noCoupleSessions: dataClientClaim.data().noCoupleSessions ? dataClientClaim.data().noCoupleSessions : '0',
                    noFamilySessions: dataClientClaim.data().noFamilySessions ? dataClientClaim.data().noFamilySessions : '0',
                    amountBilledToCFE: dataClientClaim.data().amountBilledToCFE ? (dataClientClaim.data().amountBilledToCFE.includes('$') ? dataClientClaim.data().amountBilledToCFE : '$'+dataClientClaim.data().amountBilledToCFE) : '$0',
                    totalamtpaid: '$0'
                })
            })
            // console.log(this.itemsClaims.length)
            this.latestDoc = data.docs[data.docs.length - 1]
            this.loadingInfiniteScroll = false
            this.isBusy = false
        },
        loadMoreClaims() {
            if(this.activeFilters && !this.isClinician) {
                this.getClaimsServer()
            } else {
                this.getClaimsLocal()
            }
            this.loadingInfiniteScroll = true
            // this.perPage += 10
        },
    },
    created() {
        this.firstLoadDOM = false
        this.firstLoadData = false
        this.readyToExport = true
        this.isBusy = false
        this.activeFilters = false
        this.isClinician = ''
        this.activeFilterFunction()

        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            this.isClinician = ''
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }

            if(doc.data().role === 'clinician') {
                this.isClinician = doc.id
                this.clinicianName = {
                    uid: doc.id,
                    name: doc.data().firstName + ' ' + doc.data().lastName
                }
            } else {
                this.arrayAllClaims = []
                this.getClaimsLocal()
                this.isBusy = true
                this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/clinicians-reports')
                .then(res => {
                    // this.arrayAllClaims = res.data
                    // console.log(res.data.length)
                    this.firstLoadDOM = true
                    this.setSnapshotClientActivityReportsClinician(res.data)
                })
                .catch(error => {
                    console.log(error)
                })
            }

            if(doc.data().role === 'clinician' || doc.data().role === 'staff') {
                db.collection('clientsActivity').where('clinician', 'array-contains', user.uid).get()
                .then(engaged => {
                    var ident = 1
                    var arrayClients = []
                    this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
                    engaged.forEach(clients => {
                        db.collection('clients').doc(clients.data().client).get()
                        .then(item => {
                            if(item.data().firstName && item.data().lastName) {
                                if(arrayClients.filter(elt => elt === item.id).length === 0) {
                                    arrayClients.push(item.id)
                                    var codigo = item.data().id ? item.data().id : ''
                                    this.optionsClientName.push({id: ident, codigo, uid: item.id, value: item.data().firstName + ' ' + item.data().lastName})
                                    ident += 1
                                }
                            }
                        })
                    })
                })
            } else {
                this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clients')
                .then(res => { 
                    this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
                    res.data.forEach(ele => {
                        this.optionsClientName.push(ele)
                    })
                })
            }
        })

        var year = new Date().getFullYear()
        for(var i = year - 30; i <= year + 10; i++) {
            this.optionsClaimDateYear.push(i.toString())
        }

        // db.collection('taxonomies').doc('client').get()
        // .then(docTaxonomia => {
        //     // profile fields
        //     this.optionsLanguage = docTaxonomia.data().primaryLanguage
        //     this.optionsInsurance = docTaxonomia.data().insuranceType
        // })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                arrayProgram.push({value: item, text: item})
            })
            this.optionsProgram = arrayProgram
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clinicians')
        .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
                this.optionsClinicianName.push(ele)
            })
        })
    }
}
</script>

<style>
.clinicianReportTable thead tr th {
    text-transform: none !important;
}
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>